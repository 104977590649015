import { errorStatusMessage } from './errorStatusMessage';

export const locale = {
  ...errorStatusMessage,
  changeLang: 'Change language',
  changePas: 'Change pasword',
  logout: 'Logout',
  other: 'Other',
  successAuth: 'Success authorization',
  notRules: 'Insufficient login privileges',

  newPlayer: 'New player',
  userName: 'Username',
  password: 'Password',
  createAccount: 'Create account',
  /* '/user-create': 'Cash register - New agent', */
  '/user-create-1': 'New player',
  '/user-create-2': 'New agent',
  '/user-create-3': 'New admin',
  '/total': 'Cashier transactions',
  '/': 'Menu',
  '/locale': 'Languagies',
  '/user-edit': 'Cash register - User edit',
  '/change-pas': 'Cash register - Change password',
  '/cashier-jackpot': 'Cash register - Cashier jackpot',
  '/users-list': 'Cash register - User cards balances',
  '/totalBalance': 'Balance',
  '/tree': 'Tree',
  '/active-requests': 'Active withdrawal requests',
  userCardBalances: 'Users cards balances',
  userCard: 'User card',
  accountBalance: 'Account balance',
  nickname: 'Nickname',
  id: 'ID',
  betsMenu: 'Bets',
  balance: 'Balance',
  ban: 'Ban',
  banSuccess: 'Block success',
  unbanSuccess: 'Unblock success',
  unban: 'Unban',
  resetPassword: 'Reset password',
  resetSuccess: 'Reset password success',
  networkError: 'Network error',
  createSuccess: 'Create success',
  topUp: 'TopUp',
  top_up: 'Top up',
  page: 'Page',
  withdrawal: 'Withdrawal',
  topUpSuccess: 'TopUp success',
  withdrawalSuccess: 'Withdrawal success',
  topUpWithdrawal: 'TopUp/Withdrawal',
  ok: 'Ok',
  theNewPassword: 'The new password',
  for: 'for',
  all: 'All',
  '/transaction-history': 'Transaction history',
  startDate: 'Start date',
  endingDate: 'Ending date',
  display: 'Display',
  email: 'E-mail',
  date: 'Date',
  cashierID: 'Cashier ID',
  amount: 'Amount',
  languageSelection: 'Language selection',
  login: 'Login',

  betTypes: {
    all: 'All types',
    bet: 'Bet',
    win: 'Win',
    returned: 'Returned',
    cashedOut: 'Cashed out',
    cashed_out: 'Cashed out',
    lost: 'Lost',
    rollback: 'Rollback',
    refund: 'Refund',
  },
  betType: 'Bet type',
  selectPeriod: 'Select period',
  from: 'From',
  to: 'To',
  apply: 'Apply',
  betTableHeads: {
    id: 'Id',
    type: 'Type',
    fromUserId: 'From user id',
    toUserId: 'To user id',
    value: 'Value',
    currency: 'Currency',
    note: 'Note',
    createdAt: 'Created at',
    description: 'Description',
    balanceBefore: 'Balance before',
  },
  noBets: 'No bets',

  cashRegister: 'Cash register',
  createUser: {
    1: 'New player',
    2: 'New agent',
    3: 'New admin',
    7: 'New superadmin',
  },
  balances: {
    1: 'User cards balances',
    2: 'Shop cards balances',
    3: 'Admins cards balances',
    7: 'Superadmins cards balances',
  },
  cashiersTransactions: {
    1: 'Cashier transactions',
    2: 'Shop transactions',
    3: 'Admins transactions',
    7: 'Superadmins transactions',
  },
  bets: {
    1: 'Bets',
    2: 'Bets',
    3: 'Bets',
  },
  partners: 'Partners',
  revenuBalances: 'Revenu balances',
  transactionHistory: {
    1: 'Transaction history',
    2: 'Transaction history',
    3: 'Transaction history',
    7: 'Transaction history',
  },
  cashierJackpot: {
    1: 'Cashier jackpot',
    2: 'Cashier jackpot',
    3: 'Cashier jackpot',
    7: 'Cashier jackpot',
  },
  totalBalance: {
    1: 'Balance',
    2: 'Balance',
    3: 'Balance',
    7: 'Balance',
  },
  tree: {
    1: 'Tree',
    2: 'Tree',
    3: 'Tree',
    7: 'Tree',
  },
  casinoHistory: {
    1: 'Casino history',
    2: 'Casino history',
    3: 'Casino history',
    7: 'Casino history',
  },
  casinoHistory2: 'Casino history',

  total: 'Total',
  term: 'Term',
  deposit: 'Deposit',
  withdraw: 'Withdraw',
  cashPayment: 'Cash payment',
  cashPayout: 'Cash payout',
  search: 'Search',
  errorPassword:
    'Password must consist of: at least 8 characters, at least one number, one upper and lower case letters!',
  noPass: 'Password must not be empty!',
  dataUpdate: 'Data is updated once every 12 hours.Therefore, the data for the current day may be incorrect.',
  treeMenu: 'Tree',

  players: 'Players',
  subusers: 'Subusers',

  jackpotWinnersList: 'Jackpot winners list',
  startAmount: 'Start amount',

  winnerID: 'Winner ID',
  winDate: 'Win date',
  amountOfTheWinningJackpot: 'Amount of the winning jackpot',

  totalWithdrawal: 'Total withdrawal',
  totalTopUp: 'Total deposit',

  provider: 'Provider',
  gameType: 'Game type',

  newAgent1: 'New player',
  newAgent2: 'New agent',
  newAgent3: 'New admin',

  home: 'Home',
  changeLanguage: 'Change language',
  changePassword: 'Change password',
  changedPasswordDoNotMatch: 'Passwords do not match',
  oldPassword: 'Old password',
  newPassword: 'New password',
  confirmNewPassword: 'Confirm New Password',
  enterOldPassword: 'Enter old password',
  enterNewPassword: 'Enter new password',
  repeatNewPassword: 'Repeat new password',
  save: 'Save',

  typeSomething: 'Type something',

  userEdit: 'User edit',

  userId: 'User ID',
  showResults: 'Show results',
  bet: 'bet',
  outcome: 'Outcome',
  fail: 'fail',

  role: {
    0: 'Player',
    1: 'Cashier',
    2: 'Admin',
    3: 'Suadmin',
  },
  'The password can contain only letters and numbers': 'The password can contain only letters and numbers',
  error: 'Error',
  lost: 'Lost',

  wantLogout: 'Are you sure you want to logout?',
  yesLogout: 'Yes, Logout',
  noIStay: "No, I'm staying here",
  welcomeToPanel: 'Welcome to Admin Panel!',
  useLeftMenu: 'Use the left menu to manage users',
  adminPanel: 'Admin panel',
  userData: 'User data',

  withdrawalRequests: {
    1: 'Withdrawal requests',
    2: 'Withdrawal requests',
    3: 'Withdrawal requests',
    7: 'Withdrawal requests',
  },
  status: 'Status',
  playerId: 'Player ID',
  phone: 'Phone',
  edit: 'Edit',
  request: 'Request',
  approve: 'Approve',
  decline: 'Decline',
  nameOrId: 'Type login or ID',
  username: 'Username',
  bank: {
    1: 'Bank',
    2: 'Bank',
    3: 'Bank',
    7: 'Bank',
  },
  pendingTopup: {
    1: 'Pending top up requests',
    2: 'Pending top up requests',
    3: 'Pending top up requests',
    7: 'Pending top up requests',
  },
  pendingWithdrawal: {
    1: 'Pending withdrawal requests',
    2: 'Pending withdrawal requests',
    3: 'Pending withdrawal requests',
    7: 'Pending withdrawal requests',
  },
  topUpRequests: {
    1: 'Top up requests',
    2: 'Top up requests',
    3: 'Top up requests',
    7: 'Top up requests',
  },

  createRequisite: 'Create requisite',
  requisite: 'Requisite',
  create: 'Create',
  removeRequisite: 'Remove requisite',

  statuses: {
    0: 'Raw payment',
    1: 'Accepted payment',
    2: 'Rejected payment',
    3: 'Refunded payment',
    4: 'Error payment',
  },
  allStatuses: 'All statuses',
  chooseBank: 'Choose bank',
  activeRequestsStatuses: {
    0: 'Created',
    1: 'Paid',
    2: 'Canceled',
    3: 'Refunded',
    4: 'Error',
  },
  receipt: 'Receipt',
  paymentRequisite: 'Payment requisite',
  paymentSystem: 'Payment system',
  amountFrom: 'Amount from',
  amountTo: 'Amount to',

  newTopUp: 'New top up by user',
  newWithdrawal: 'New withdrawal by user',
  newPaymentsPending: 'You have new payments pending',

  loginTo: 'Login to',
  newPasswordDescription:
    'Password must consist of: at least 8 characters, at least one number, one upper and lower case letters!',

  activeRequests: {
    1: 'Active withdrawal requests',
    2: 'Active withdrawal requests',
    3: 'Active withdrawal requests',
    7: 'Active withdrawal requests',
  },
  userLogin: 'User login',
  newRequest: 'New Request',
  reject: 'Reject',
  confirm: 'Confirm',
  requestRejection: 'Request Rejection',
  youRejected: 'You rejected a request in the amount of',
  comment: 'Comment',
  writeExplanation: 'Write an explanation why the request was rejected',
  send: 'Send',

  withdrawalHistory: {
    1: 'History of withdrawals',
    2: 'History of withdrawals',
    3: 'History of withdrawals',
    7: 'History of withdrawals',
  },
  '/withdrawal-history': 'History of withdrawals',
  completed: 'Completed',
  created: 'Created',
  success: 'Success',
  show: 'Show',
  rejected: 'Rejected',
  withdrawalAmount: 'Withdrawal amount',

  log_out: 'Log out',
};
