import React, { useMemo, useCallback } from 'react';
import block from 'bem-cn';
import { Link, withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import SVG from 'components/SVG';
import { actions as authActions } from 'features/Auth';
import { formatNumberWithLimit } from 'shared/utils/formatNumber';

import homeSvg from '../img/home.svg';
import coinsSvg from '../img/coins.svg';
import userSvg from '../img/user.svg';
import logoutSvg from '../img/logout.svg';

import './Header.scss';

const b = block('header');

const Header = () => {
  const locale = useSelector(state => state.locale.locale);
  const { isAuth, balance, currency, name, id } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const handleLogOut = () => {
    dispatch(authActions.logOut());
  };

  const balanceFormatted = formatNumberWithLimit(balance);

  return (
    <div className={b({ isAuth })}>
      {isAuth ? (
        <>
          {/*<Link to="/" className={b('wrapper')}>*/}
          {/*  <SVG svgProps={{ svg: homeSvg }} className={b('home')} />*/}
          {/*  <div className={b('user')}>{locale.adminPanel}</div>*/}
          {/*</Link>*/}
          <div className={b('block')}>
            <div className={b('row')}>
              <div className={b('id-label')}>ID</div>
              <div className={b('id-value')}>{id}</div>
            </div>
            <div className={b('row')}>
              <span>{name}:</span>
              {balanceFormatted}
              <div className={b('currency')}>{currency}</div>
            </div>
          </div>
          <div className={b('logout')} onClick={handleLogOut}>
            <SVG svgProps={{ svg: logoutSvg }} containerClassName={b('logout-icon')} />
            <div className={b('logout-text')}>{locale.log_out}</div>
          </div>
          {/*<div className={b('block', { type: 'logout' })}>*/}
          {/*  <SVG svgProps={{ svg: userSvg }} className={b('icon')} />*/}
          {/*  {name}*/}
          {/*  <SVG svgProps={{ svg: logoutSvg }} className={b('icon', { type: 'logout' })} onClick={handleLogOut} />*/}
          {/*</div>*/}
        </>
      ) : (
        <h3 className={b('title')}>{locale.role[2]}</h3>
      )}
    </div>
  );
};

export default withRouter(Header);
