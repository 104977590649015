import React from 'react';
import block from 'bem-cn';
import { useSelector, shallowEqual } from 'react-redux';

import InputDate from '../../../../../../components/InputDate';
import './DateRangeFilter.scss';
import dayjs from 'dayjs';

const b = block('date-ranges-filter');

const DateRangeFilter = ({ startDate, endDate, setStartDate, setEndDate }) => {
  const locale = useSelector(state => state.locale.locale, shallowEqual);

  const handleStartDateChange = date => {
    setStartDate(date);
  };

  const handleEndDateChange = date => {
    setEndDate(date);
  };

  return (
    <div className={b()}>
      <div className={b('filter-item')}>
        <div className={b('filter-title')}>{`${locale.from}:`}</div>
        <div className={b('date-input')}>
          <InputDate
            selected={new Date(dayjs(startDate))}
            onChange={handleStartDateChange}
            dateFormat="dd.MM.yyyy, HH:mm"
            showTimeSelect
          />
        </div>
      </div>
      <div className={b('filter-item')}>
        <div className={b('filter-title')}>{`${locale.to}:`}</div>
        <div className={b('date-input')}>
          <InputDate
            selected={new Date(dayjs(endDate))}
            onChange={handleEndDateChange}
            dateFormat="dd.MM.yyyy, HH:mm"
            showTimeSelect
          />
        </div>
      </div>
    </div>
  );
};

export default DateRangeFilter;
