import { useState, useRef, useEffect  } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import debounce from 'lodash.debounce';

import { actions as usersActions } from 'features/users/redux';

const useUsersList = ({ count } = { count: 10 }) => {
  const dispatch = useDispatch();
  const {
    actionProcessing,
    usersList: { users, quantity, totalBalance }
  } = useSelector(state => state.users, shallowEqual);

  const [values, setValues] = useState({
    username: '',
    page: 0,
    count,
  });

  const pages = Math.ceil(quantity / values.count);

  const debouncedSearch = useRef(debounce(data => {
    dispatch(usersActions.getUsersList(data));
  }, 750));

  const handleUsernameChange = v => {
    setValues(ps => {
      const newState = {
        ...ps,
        username: v,
      };

      debouncedSearch.current(newState);
      return newState;
    })
  }

  const handlePageChange = v => {
    setValues(ps => ({
      ...ps,
      page: v,
    }));
  }

  useEffect(() => {
    dispatch(usersActions.getUsersList(values));
  }, [dispatch, values.page]);

  useEffect(() => {
    if (values.page + 1 > pages) handlePageChange(0);
  }, [pages]);

  return ({
    data: {
      values,
      users,
      pages,
      quantity,
      totalBalance,
      actionProcessing,
    },
    handlers: {
      handleUsernameChange,
      handlePageChange,
    },
  })
}

export default useUsersList;

