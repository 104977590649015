export const initialState = {
  actionProcessing: false,
  transactions: [],
  total_deposit: 0,
  total_withdrawal: 0,
  activeRequests: {
    items: [],
  },
  withdrawalHistory: {
    items: [],
  },
};
