import React from 'react';
import block from 'bem-cn';
import { useSelector } from 'react-redux';

import Button from 'components/Button';

import './ActiveRequestItem.scss';

const b = block('active-request-item');

const ActiveRequestItem = ({ item, onApprove, onReject, currency }) => {
  const locale = useSelector(state => state.locale.locale);

  return (
    <section className={b()}>
      <div className={b('item')}>
        <div className={b('item-text')}>ID</div>
        <div className={b('item-value')}>{item.id}</div>
      </div>

      <div className={b('item')}>
        <div className={b('item-text')}>{locale.date}</div>
        <div className={b('item-value')}>{item.date}</div>
      </div>

      <div className={b('item')}>
        <div className={b('item-text')}>{locale.username}</div>
        <div className={b('item-value')}>{item.userLogin}</div>
      </div>

      <div className={b('item')}>
        <div className={b('item-text')}>{locale.newRequest}</div>
        <div className={b('item-value', { red: true })}>
          {locale.amount}: <span>{item.amount}</span>
        </div>
      </div>

      <div className={b('button')}>
        <Button color="transparent" onClick={onReject}>
          <span className={b('button-text', { red: true })}>{locale.reject}</span>
        </Button>
      </div>

      <div className={b('button')}>
        <Button onClick={onApprove}>
          <span className={b('button-text')}>{locale.confirm}</span>
        </Button>
      </div>
    </section>
  );
};

export default ActiveRequestItem;
