import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

import { useStatusesName } from './data';
import { actions } from 'features/transaction';
import { convertWithdrawalHistory } from './convertWithdrawalHistory';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export const useWithdrawalHistory = () => {
  const dispatch = useDispatch();
  const locale = useSelector(state => state.locale.locale);
  const { actionProcessing, withdrawalHistory } = useSelector(state => state.transaction, shallowEqual);
  const { currency } = useSelector(state => state.auth, shallowEqual);

  const statusesName = useStatusesName();

  const [values, setValues] = useState({
    page: 0,
    status: [1, 2],
    count: 7,
    login: '',
    amount: '',
    userId: '',
  });

  const [dateRanges, setDateRanges] = useState({
    from: dayjs().subtract(3, 'day').startOf('day').toDate(),
    to: dayjs().endOf('day').toDate(),
  });

  const [requestData, setRequestData] = useState({});

  const handleLoginChange = value => {
    setValues(ps => ({ ...ps, login: value, page: 0 }));
  };

  const handleAmountChange = value => {
    setValues(ps => ({ ...ps, amount: value, page: 0 }));
  };

  const handleUserIdChange = value => {
    setValues(ps => ({ ...ps, userId: value, page: 0 }));
  };

  const handleFromChange = value => {
    setDateRanges(ps => ({ ...ps, from: value, page: 0 }));
  };

  const handleToChange = value => {
    setDateRanges(ps => ({ ...ps, to: value, page: 0 }));
  };

  const handleStatusChange = value => {
    if (values.status.includes(value)) {
      setValues(ps => ({
        ...ps,
        status: ps.status.filter(item => item !== value),
        page: 0,
      }));
    } else {
      setValues(ps => ({
        ...ps,
        status: [...ps.status, value],
        page: 0,
      }));
    }
  };

  const handlePageChange = page => {
    setValues(ps => ({ ...ps, page }));
  };

  const handleWithdrawalHistorySubmit = e => {
    e.preventDefault();

    const requestData = {
      withdrawal_amount: values.amount,
      player_id: values.userId,
      player_login_pattern: values.login,
      date_from: dayjs(dateRanges.from).format('YYYY-MM-DD HH:mm'),
      date_to: dayjs(dateRanges.to).format('YYYY-MM-DD HH:mm'),
    };

    if (values.status.length === 1) {
      requestData.status = values.status[0] === 1 ? 0 : values.status[0] === 2 ? 1 : values.status[0];
    }

    Object.keys(requestData).forEach(key => {
      if (requestData[key] === '' || requestData[key] === null || requestData[key] === undefined) {
        delete requestData[key];
      }
    });

    setRequestData(requestData);
    dispatch(actions.getWithdrawalHistory(requestData));
  };

  useEffect(() => {
    dispatch(actions.getWithdrawalHistory(requestData));
  }, [requestData, dispatch]);

  const pages = Math.ceil((withdrawalHistory.items || []).length / values.count);

  const items = (withdrawalHistory.items || [])
    .slice(values.page * values.count, (values.page + 1) * values.count)
    .map(item => ({
      ...item,
      date: dayjs(item.date).format('DD.MM.YYYY, HH:mm'),
      amount: `${item.amount} ${currency}`,
      statusName: statusesName[item.status] ?? '',
    }));

  return {
    input: {
      values,
      dateRanges,
      handleFromChange,
      handleToChange,
      handleStatusChange,
      handlePageChange,
      handleWithdrawalHistorySubmit,
      handleLoginChange,
      handleAmountChange,
      handleUserIdChange,
    },
    output: {
      locale,
      actionProcessing,
      items,
      pages,
    },
  };
};
