import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

export const MOCK = {
  data: [
    {
      id: 2,
      date: '2022-08-29 13:43:58',
      amount: 5,
      status: 1,
      comment: '',
      payment_system: 'bank name',
      payment_requisite: '321315432523',
      img_url: 'url',
      id_of_the_transaction: '09812676789312',
    },
  ],
  meta: {
    pages: 77,
  },
};

export class WithdrawalConverter {
  convertItem = t => ({
    id: t.id,
    date: dayjs(t.date, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm:ss'),
    amount: t.amount,
    status: t.status,
    comment: t.comment,
    paymentSystem: t.payment_system,
    paymentRequisite: t.payment_requisite,
    transactionId: t.id_of_the_transaction,
    imageUrl: `https://bossi.bet/media/${t.img_url}`,
    playerId: t.player_id ?? '',
  });

  convertWithdrawalRequests = d => {
    return {
      list:
        d.data
          ?.sort((a, b) => dayjs(b.create_date) - dayjs(a.create_date))
          ?.map(t => ({
            id: t.id,
            playerId: t.player_id,
            amount: t.amount,
            status: t.status,
            date: dayjs(t.create_date, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm:ss'),
            requisite: t.requisite,
            phone: t.phone,
            bank: t.bank,
            requisite: t.requisite,
          })) || [],
      pages: d.meta.pages,
    };
  };

  convertTopUpRequests = data => {
    const list = data?.data?.sort((a, b) => dayjs(b.date) - dayjs(a.date))?.map(t => this.convertItem(t));

    return {
      list,
      pages: data?.meta?.pages ?? 1,
    };
  };
}
