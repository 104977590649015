import React, { useMemo } from 'react';
import block from 'bem-cn';
import { useSelector, shallowEqual } from 'react-redux';
import { Link } from 'react-router-dom';

import { formatNumberWithLimit } from 'shared/utils/formatNumber';

import PaginatorDropdownSelector from 'components/Paginator/PaginatorDropdownSelector/mobile';
import InputSearch from 'components/InputSearch';
import SVG from 'components/SVG';
import Spinner from 'components/Spinner';

import useUsersList from '../../model/useUsersList';
import arrowSvg from './img/arrow.svg';
import './UsersList.scss';

const b = block('users-list');

const UsersList = () => {
  const locale = useSelector(state => state.locale.locale, shallowEqual);

  const { data, handlers } = useUsersList();

  const list = useMemo(
    () =>
      data.users.map(item => (
        <Link key={item.id} className={b('item')} to={`/user-edit/${item.id}`}>
          <div className={b('item-text')}>{`${item.nickname}`}</div>
          <div className={b('item-balance')}>{formatNumberWithLimit(item.balance)}</div>
          <SVG svgProps={{ svg: arrowSvg }} className={b('item-arrow')} />
        </Link>
      )) || [],
    [data.users],
  );

  return (
    <div className={b()}>
      <Spinner isLoading={data.actionProcessing} />
      <h3 className={b('title')}>{locale.userCardBalances}</h3>
      <form className={b('filter')} onSubmit={e => e.preventDefault()}>
        <div className={b('search')}>
          <div className={b('search-input')}>
            <InputSearch
              value={data.values.username}
              onChange={e => handlers.handleUsernameChange(e.currentTarget.value)}
              placeholder={locale.nameOrId}
            />
          </div>
        </div>
        <div className={b('subheader-data-item')}>
          <div className={b('subheader-data-item-nick')}>{locale.userCard}</div>
          <div className={b('subheader-data-item-value')}>{data.quantity}</div>
        </div>
        <div className={b('subheader-data-item')}>
          <div className={b('subheader-data-item-nick')}>{locale.accountBalance}</div>
          <div className={b('subheader-data-item-value')}>{formatNumberWithLimit(data.totalBalance)}</div>
        </div>
      </form>
      <div className={b('list')}>{list}</div>
      {!!data.users.length && <div className={b('paginator')}>
        <PaginatorDropdownSelector
          currentPage={data.values.page}
          pageCount={data.pages}
          onPageClick={handlers.handlePageChange}
        />
      </div>}
    </div>
  );
};

export default UsersList;
