import EnPNG from './img/en.png';
import FrPNG from './img/fr.png';

export const languages = {
  EN: 'en-US',
  FR: 'fr-fr',
};

export const languagesWithIcons = {
  [languages.EN]: { lang: languages.EN, icon: EnPNG, text: 'English' },
  [languages.FR]: { lang: languages.FR, icon: FrPNG, text: 'French' },
};

export const shortLanguages = {
  [languages.EN]: 'en',
  [languages.FR]: 'fr',
};

const rtlLangs = [];

export const checkIsRtlLang = lang => {
  return rtlLangs.includes(lang);
};
