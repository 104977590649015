import React from 'react';
import { useSelector } from 'react-redux';

const useStatusesName = () => {
  const locale = useSelector(state => state.locale.locale);

  return {
    0: locale.created,
    1: locale.completed,
    2: locale.rejected,
  };
};

export { useStatusesName };
