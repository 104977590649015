// сафари не переваривает специфические форматы

// превращает 2022-01-01 в 01.01.2022
export const dateFormateForSafari = date => {
  if (!date) return '__.__.____'; // возврат заглушки, если дата не указана

  let formattedDate = date.trim(); // удаление лишних пробелов

  // Проверяем текущий формат даты
  if (formattedDate.includes('-')) {
    // Если дата в формате yyyy-mm-dd
    const [correctDate, time = ''] = formattedDate.split(' ');
    const timeSuffix = time ? ` ${time}` : '';
    const parts = correctDate.split('-');
    formattedDate = `${parts[2]}.${parts[1]}.${parts[0]}${timeSuffix}`;
  } else if (formattedDate.includes('.')) {
    // Если дата уже в формате dd.mm.yyyy, оставляем как есть
    // Можно добавить дополнительную обработку, если требуется
  }

  return formattedDate;
};
