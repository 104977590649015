import React from 'react';
import block from 'bem-cn';

import PaginatorDropdownSelector from 'components/Paginator/PaginatorDropdownSelector/mobile';

import { useActiveRequests } from '../../../model/activeRequests/useActiveRequests';
import ActiveRequestItem from './ActiveRequestItem/ActiveRequestItem';
import ActiveRequestModal from './ActiveRequestModal/ActiveRequestModal';
import './ActiveRequests.scss';
import BackBlock from '../../../../../components/BackBlock';

const b = block('active-requests-desktop');

const ActiveRequests = () => {
  const { input, output } = useActiveRequests();

  const items = output.items.map(item => (
    <ActiveRequestItem
      key={item.id}
      item={item}
      onApprove={() => input.handleRequestApprove({ id: item.id })}
      onReject={() => input.handleItemOpen(item)}
      currency={output.currency}
    />
  ));

  return (
    <>
      <div className={b('title')}>{output.locale['/active-requests']}</div>
      <article className={b()}>
        <ActiveRequestModal
          item={input.activeItem}
          note={input.values.note}
          onClose={input.handleItemClose}
          onNoteChange={input.handleNoteChange}
          onSend={input.handleRequestReject}
        />
        {items}
      </article>
      <div className={b('paginator')}>
        <PaginatorDropdownSelector
          currentPage={input.values.page}
          pageCount={output.pages}
          onPageClick={input.handlePageChange}
        />
      </div>
    </>
  );
};

export default ActiveRequests;
