import React, { useEffect } from 'react';
import block from 'bem-cn';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { actions as authAction } from 'features/Auth';
import Notify from 'features/notify/view/desktop';
import NotifyPaymentPending from 'features/notifyPaymentPending/view/desktop';
import CreateUser from 'features/users/view/desktop/CreateUser';
import UsersList from 'features/users/view/desktop/UsersList';
import UserEdit from 'features/users/view/desktop/UserEdit';
import ChangePas from 'features/ChangePas/desktop';
import Total from 'features/Total/view/desktop';
import Tree from 'features/tree/view/desktop';
import Bets from 'features/bets/view/desktop';
import CashierJackpot from 'features/cashierJackpot/view/desktop';
import SlotsBetHistory from 'features/slotsBetHistory/view/desktop';
import TransactionHistory from 'features/transaction/view/desktop/TransactionHistory';
import Withdrawal from 'features/withdrawal/view/desktop';
import TopUp from 'features/topUp/view/desktop';
import AuthModal from 'features/Auth/view/desktop/AuthModal/AuthModal';
import SoundNotification from 'features/notifyPaymentPending/view/mobile/SoundNotification';

import {
  RequisitesList,
  CreateRequisite,
  ChangingForm,
  RequestsHistory,
  WithdrawalRequestsHistory,
} from 'features/PaymentAdmin/desktop';

import Footer from 'components/Footer/desktop';
import Header from 'components/Header/desktop';
import AsyncPollBalance from 'components/AsyncPoll/AsyncPollBalance';
import AsyncPollNotify from 'components/AsyncPoll/AsyncPollNotify';

import Locale from 'features/locale/view/desktop';
import Auth from 'features/Auth/view/desktop';
import Menu from 'components/Menu/desktop';
import logoIMG from 'shared/img/logo.png';

import './App.scss';
import ActiveRequests from '../../../features/transaction/view/desktop/ActiveRequests/ActiveRequests';
import WithdrawalHistory from '../../../features/transaction/view/desktop/WithdrawalHistory/WithdrawalHistory';

const b = block('app');

const App = ({ location }) => {
  const dispatch = useDispatch();

  const locale = useSelector(state => state.locale.locale);
  const isAuth = useSelector(state => state.auth.isAuth);
  const { role } = useSelector(state => state.auth);
  const isModalOpen = useSelector(state => state.auth.modal.isOpen);
  const user = useSelector(state => state.auth);

  useEffect(() => {
    dispatch(authAction.checkAuth());
  }, [dispatch]);

  return (
    <main className={b()}>
      <Notify />
      {role === 1 && <NotifyPaymentPending />}
      {/* {isAuth && <Subheader user={user} getBalance={authAction.getBalance} />} */}
      {isModalOpen && <AuthModal />}
      {isAuth ? (
        <section className={b('main')}>
          <nav className={b('menu')}>
            <Menu isDesktop />
            {/*<Footer locale={locale} isAuth={isAuth} />*/}
          </nav>
          <section className={b('content')}>
            {isAuth && <Header />}
            <div className={b('container')}>
              <Switch>
                <Redirect exact to="/user-create" from="/" />
                <Route exact key="/user-create" path="/user-create" component={CreateUser} />
                <Route exact key="/users-list" path="/users-list" component={UsersList} />
                <Route exact key="/total" path="/total" component={Total} />
                <Route exact key="/user-edit/:id" path="/user-edit/:id" component={UserEdit} />
                <Route exact key="/transaction-history" path="/transaction-history" component={TransactionHistory} />
                <Route exact key="/change-pas" path="/change-pas" component={ChangePas} />
                <Route exact key="/tree" path="/tree" component={Tree} />
                <Route exact key="/casino-history" path="/casino-history" component={SlotsBetHistory} />
                <Route exact key="/bets" path="/bets" component={Bets} />
                <Route exact key="/cashier-jackpot" path="/cashier-jackpot" component={CashierJackpot} />
                <Route exact key="/locale" path="/locale" component={Locale} />

                <Route exact key="/pending-withdrawal" path="/pending-withdrawal" component={Withdrawal} />
                <Route key="/pending-top-up" path="/pending-top-up" component={TopUp} />
                <Route exact key="/requisites" path="/requisites" component={RequisitesList} />
                <Route exact key="/requisites-create" path="/requisites-create" component={CreateRequisite} />
                <Route key="/requisites-detail" path="/requisites-detail" component={ChangingForm} />
                <Route key="/top-up-history" path="/top-up-history" component={RequestsHistory} />

                <Route exact key="/active-requests" path="/active-requests" component={ActiveRequests} />
                <Route exact key="/withdrawal-history" path="/withdrawal-history" component={WithdrawalHistory} />
              </Switch>
            </div>
          </section>
        </section>
      ) : (
        <Auth />
      )}
      <AsyncPollBalance />
      {/*<AsyncPollNotify />*/}
      <SoundNotification />
    </main>
  );
};

export default withRouter(App);
