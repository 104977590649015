/* eslint-disable no-unused-expressions */
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import block from 'bem-cn';

import SVG from 'components/SVG';
import { validateEmail } from 'shared/helpers';
import passwordSVG from '../img/password.svg';
import passwordOffSVG from '../img/passwordOff.svg';

import './Input.scss';

const b = block('input-mobile');

const Input = ({
  color = 'default',
  fontSize = '14',
  value,
  name,
  placeholder = '',
  type = 'text',
  measurement = '',
  onChange,
  ...restProps
}) => {
  useEffect(() => {
    if (value) {
      if (name === 'email') restProps.setIsValid?.(validateEmail(value));
      else restProps.setIsValid?.(value.toString().length > 0);
    }
  }, []);

  const [passwordVisible, setPasswordVisible] = useState(false);
  const isPassword = useMemo(() => Boolean(name && name.toLowerCase().indexOf('password') > -1), [name]);
  const passwordClick = useCallback(
    () => setPasswordVisible(!passwordVisible && isPassword),
    [passwordVisible, isPassword],
  );

  return (
    <div className={b({ color, fontSize })}>
      {isPassword && (
        <>
          <SVG
            svgProps={{ svg: passwordSVG }}
            className={b('password', { active: !passwordVisible })}
            onClick={passwordClick}
          />
          <SVG
            svgProps={{ svg: passwordOffSVG }}
            className={b('password-off', { active: passwordVisible })}
            onClick={passwordClick}
          />
        </>
      )}
      {measurement && <span className={b('measurement')}>{measurement}</span>}
      <input
        className={b('native', {
          type: isPassword ? 'password' : type,
          activePassword: isPassword && !passwordVisible,
          valid: restProps.isValid,
          hasMeasurement: Boolean(measurement),
        })}
        type={/* isPassword */ passwordVisible ? 'text' : type}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={e => {
          if (onChange) onChange(e);
          if (name === 'email') restProps.setIsValid?.(validateEmail(e.target.value));
          else restProps.setIsValid?.(e.target.value.toString().length > 0);
        }}
        {...restProps}
      />
    </div>
  );
};

export default Input;
