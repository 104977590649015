import React, { useMemo, useCallback, useState } from 'react';
import block from 'bem-cn';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { languagesWithIcons, shortLanguages } from 'services/locale';

import { actions as userSettingsActions } from 'features/locale';

import SVG from 'components/SVG';

import arrowSVG from './img/arrow.svg';
import langSVG from './img/lang.svg';
import markerSVG from './img/marker.svg';

import './LanguageDropdownSelector.scss';

const b = block('language-dropdown-selector-mobile');

const LanguageDropdownSelector = ({ menuLayout }) => {
  const dispatch = useDispatch();

  const lang = useSelector(state => state.locale.lang);

  const changeLang = useCallback(
    newLang => {
      dispatch(userSettingsActions.changeLang(newLang));
    },
    [dispatch],
  );

  const [isOpen, changeOpen] = useState(false);

  const items = useMemo(
    () =>
      Object.values(languagesWithIcons).map(item => {
        const isCurent = lang === item.lang;
        return (
          <div className={b('item', { active: isCurent })} onClick={() => changeLang(item.lang)} key={item.text}>
            <div className={b('lang')}>
              <img className={b('flag')} key={item.lang} src={item.icon} alt={item.text} />
              <span className={b('lang-name')}>{shortLanguages[item.lang]}</span>
            </div>
          </div>
        );
      }),
    [lang, dispatch],
  );

  return (
    <div className={b({ isOpen })} onClick={() => changeOpen(!isOpen)}>
      <div className={b('current-item', { isOpen, menuLayout })}>
        <div className={b('current-lang')}>
          <img
            className={b('current-flag')}
            src={languagesWithIcons[lang]?.icon}
            alt={languagesWithIcons[lang]?.text}
          />
          <span className={b('current-lang-name')}>{languagesWithIcons[lang]?.text}</span>
        </div>
        <SVG className={b('arrow', { isOpen, menuLayout }).toString()} svgProps={{ svg: arrowSVG }} />
      </div>
      {isOpen && <div className={b('items', { isOpen })}>{items}</div>}
    </div>
  );
};

export default LanguageDropdownSelector;
