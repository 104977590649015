import React, { useMemo } from 'react';
import block from 'bem-cn';
import { useSelector } from 'react-redux';

import SVG from 'components/SVG';
import logoPNG from 'shared/img/logo.png';

import menuItems from '../data';
import MenuItem from './MenuItem';

import './Menu.scss';
import pas from '../../Footer/desktop/img/pas.svg';
import arrowSvg from '../../Footer/desktop/img/arrow.svg';
import { Link } from 'react-router-dom';
import LanguageDropdownSelector from '../../Language/LanguageDropdownSelector/view/mobile';

const b = block('menu');

const Menu = () => {
  const locale = useSelector(state => state.locale.locale);
  const role = useSelector(state => state.auth.role);

  const itemList = useMemo(
    () => menuItems.map(temp => <MenuItem locale={locale} role={role} item={temp} key={temp.textId} />),
    [locale, role],
  );

  return (
    <section className={b()}>
      <Link to="/user-create">
        <img src={logoPNG} alt="" className={b('logo')} />
      </Link>
      <div className={b('list')}>{itemList}</div>

      <Link to="/change-pas" className={b('item', { active: location.pathname.startsWith('/change-pas') })}>
        <SVG svgProps={{ svg: pas }} className={b('item-img')} />
        <span className={b('item-text')}>{locale.changePas}</span>
        <SVG className={b('element-arrow')} svgProps={{ svg: arrowSvg }} />
      </Link>
      <LanguageDropdownSelector menuLayout />
    </section>
  );
};

export default Menu;
