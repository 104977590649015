import React, { useMemo } from 'react';
import block from 'bem-cn';

import CheckBox from 'components/CheckBox';
import Input from 'components/Input/mobile';
import Button from 'components/Button';
import './WithdrawalHistory.scss';
import DateRangeFilter from './DateRangeFilter/DateRangeFilter';
import { useWithdrawalHistory } from '../../../model/withdrawalHistory/useWithdrawalHistory';
import WithdrawalHistoryItem from './WithdrawalHistoryItem/WithdrawalHistoryItem';
import PaginatorDropdownSelector from 'components/Paginator/PaginatorDropdownSelector/view/mobile';
import BackBlock from '../../../../../components/BackBlock';

const b = block('withdrawal-history');

const WithdrawalHistory = () => {
  const { input, output } = useWithdrawalHistory();
  const {
    values,
    handleFromChange,
    handleToChange,
    handleStatusChange,
    handlePageChange,
    handleWithdrawalHistorySubmit,
    handleLoginChange,
    dateRanges,
    handleAmountChange,
    handleUserIdChange,
  } = input;
  const locale = output.locale;

  const items = useMemo(
    () => output.items.map(item => <WithdrawalHistoryItem key={item.id} item={item} />) || [],
    [output.items],
  );

  return (
    <>
      <div className={b('title')}>{locale['/withdrawal-history']}</div>
      <form className={b()} onSubmit={handleWithdrawalHistorySubmit}>
        <div className={b('filters')}>
          <div className={b('top-filters')}>
            <div className={b('status-filter-block')}>
              <div className={b('row')}>
                <label className={b('label')}>
                  <div className={b('checkbox')}>
                    <CheckBox checked={values.status.includes(1)} onChange={() => handleStatusChange(1)} />
                  </div>
                  {locale.completed}
                </label>
                <label className={b('label')}>
                  <div className={b('checkbox')}>
                    <CheckBox checked={values.status.includes(2)} onChange={() => handleStatusChange(2)} />
                  </div>
                  {locale.rejected}
                </label>
              </div>
            </div>
            <DateRangeFilter
              startDate={dateRanges.from}
              endDate={dateRanges.to}
              setStartDate={handleFromChange}
              setEndDate={handleToChange}
            />
          </div>
          <div className={b('bottom-filters')}>
            <div className={b('amount-filter')}>
              <div className={b('text', { fontSize: '14' })}>{locale.withdrawalAmount || 'withdrawalAmount'}</div>
              <div className={b('search-block')}>
                <Input
                  value={values.amount}
                  onChange={e => handleAmountChange(e.currentTarget.value)}
                  placeholder={locale.withdrawalAmount || 'withdrawalAmount'}
                />
              </div>
            </div>

            <div className={b('amount-filter')}>
              <div className={b('text', { fontSize: '14' })}>{locale.userId}</div>
              <div className={b('search-block')}>
                <Input
                  type="number"
                  value={values.userId}
                  onChange={e => handleUserIdChange(e.currentTarget.value)}
                  placeholder={locale.userId}
                />
              </div>
            </div>

            <div className={b('user-login-filter')}>
              <div className={b('text', { fontSize: '14' })}>{locale.userLogin}</div>
              <div className={b('search-block')}>
                <Input
                  value={values.login}
                  onChange={e => handleLoginChange(e.currentTarget.value)}
                  placeholder={locale.userLogin}
                />
              </div>
            </div>

            <div className={b('button')}>
              <Button type="submit">{locale.showResults}</Button>
            </div>
          </div>
        </div>
        <div className={b('items')}>{items}</div>

        <div className={b('paginator')}>
          <PaginatorDropdownSelector
            currentPage={values.page}
            pageCount={output.pages}
            onPageClick={handlePageChange}
          />
        </div>
      </form>
    </>
  );
};

export default WithdrawalHistory;
