import React, { useState, useCallback } from 'react';
import block from 'bem-cn';
import { useSelector, useDispatch } from 'react-redux';
import SVGInline from 'react-svg-inline';
import { Redirect } from 'react-router-dom';

import Button from 'components/Button';
import Input from 'components/Input/desktop';
import SVG from 'components/SVG';
import logoSVG from 'shared/img/logo.svg';
import LanguageDropdownSelector from 'components/Language/LanguageDropdownSelector/view/mobile';

import { actions as authActions } from 'features/Auth';
import Locale from 'features/locale/view/desktop';

import language from './img/lang.svg';
import arrowSvg from './img/arrow.svg';
import crossSVG from './img/cross.svg';

import './Auth.scss';

const b = block('auth-desktop');

const Auth = () => {
  const dispatch = useDispatch();

  const [userName, changeUserName] = useState('');
  const [password, passwordUserName] = useState('');

  const [isLoginPage, setIsLoginPage] = useState(true);

  const locale = useSelector(state => state.locale.locale);
  const { isProcess, isAuth } = useSelector(state => state.auth);

  const signIn = useCallback(() => {
    if (!isProcess) {
      dispatch(authActions.login(userName, password));
    }
  }, [userName, password, isProcess, dispatch]);

  if (isAuth) return <Redirect to="/" />;

  return (
    <article className={b()}>
      <section className={b('content')}>
        <div className={b('title')}>{locale.loginTo}</div>
        <SVG containerClassName={b('logo')} svgProps={{ svg: logoSVG }} />
        <div className={b('data')}>
          <div className={b('field')}>
            <div className={b('field-title')}>{`${locale.userName}`}</div>
            <div className={b('field-input')}>
              <Input
                value={userName}
                onChange={e => changeUserName(e.currentTarget.value)}
                name="login"
                placeholder={locale.userName}
              />
            </div>
          </div>
          <div className={b('field', { withMargin: true })}>
            <div className={b('field-title')}>{`${locale.password}`}</div>
            <div className={b('field-input')}>
              <Input
                value={password}
                onChange={e => passwordUserName(e.currentTarget.value)}
                name="password"
                type="password"
                placeholder={locale.password}
              />
            </div>
          </div>
          <div className={b('button')}>
            <Button onClick={signIn}>
              <span className={b('button-text')}>{locale.login}</span>
            </Button>
          </div>
        </div>
        <div className={b('hr')}></div>
        <div className={b('lang')}>
          <LanguageDropdownSelector />
        </div>
      </section>
    </article>
  );
};

export default Auth;
