import React, { useState, useCallback, useMemo, useEffect } from 'react';
import block from 'bem-cn';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

import { actions as transactionActions } from 'features/transaction/redux';
import { formatNumberWithLimit } from 'shared/utils/formatNumber';

import Button from 'components/Button';
import InputDate from '../../../../../components/InputDate';
import './TransactionHistory.scss';
import InputSearch from '../../../../../components/InputSearch';

const b = block('transaction-history');

const TransactionHistory = () => {
  const dispatch = useDispatch();
  const [filterValue, onChangeFilterValue] = useState('');
  const locale = useSelector(state => state.locale.locale, shallowEqual);
  const { transactions, total_deposit, total_withdrawal, actionProcessing } = useSelector(
    state => state.transaction,
    shallowEqual,
  );

  const [startDate, setStartDate] = useState(dayjs(new Date()).add(-3, 'day').$d);
  const [endDate, setEndDate] = useState(dayjs(new Date()).$d);

  const changeFilterValue = useCallback(e => {
    onChangeFilterValue(e.currentTarget.value);
  }, []);

  const onClick = useCallback(() => {
    dispatch(transactionActions.getTransactions({ startDate, endDate }));
  }, [dispatch, endDate, startDate]);

  const list = useMemo(
    () =>
      transactions
        .filter(t => ~t.email?.toUpperCase().indexOf(filterValue.toUpperCase()) || false)
        .map((item, index) => (
          // <div className={b('row')}>
          //   <span className={b('td')}>{item.email}</span>
          //   <span className={b('td')}>{item.date}</span>
          //   <span className={b('td')}>{formatNumberWithLimit(item.amount)}</span>
          //   <span className={b('td')}>{item.balance}</span>
          // </div>
          <div key={name} className={b('row')}>
            <div className={b('td')}>
              <div className={b('td-title')}>{locale.username}</div>
              <div className={b('td-value')}>{item.email}</div>
            </div>
            <div className={b('td')}>
              <div className={b('td-title')}>{locale.date}</div>
              <div className={b('td-value')}>{item.date}</div>
            </div>
            <div className={b('td')}>
              <div className={b('td-title')}>{locale.amount}</div>
              <div className={b('td-value')}>{formatNumberWithLimit(item.amount)}</div>
            </div>
            <div className={b('td')}>
              <div className={b('td-title')}>{locale.balance}</div>
              <div className={b('td-value')}>{item.balance}</div>
            </div>
          </div>
        )) || [],
    [filterValue, transactions],
  );

  return (
    <div className={b()}>
      <h3 className={b('title')}>{locale['/transaction-history']}</h3>
      <div className={b('content')}>
        <div className={b('search')}>
          <InputSearch
            value={filterValue}
            onChange={changeFilterValue}
            placeholder={locale.search}
            onClick={changeFilterValue}
          />
        </div>
        <div className={b('fields')}>
          <div className={b('field-title')}>{`${locale.from}`}</div>
          <div className={b('field-title')}>{`${locale.to}`}</div>
          <div />
          <div className={b('field-input')}>
            <InputDate
              dateFormat="yyyy-MM-dd"
              selected={new Date(dayjs(startDate))}
              onChange={date => {
                setStartDate(dayjs(date).format('YYYY-MM-DD'));
              }}
            />
          </div>
          <div className={b('field-input')}>
            <InputDate
              dateFormat="yyyy-MM-dd"
              selected={new Date(dayjs(endDate))}
              onChange={date => {
                setEndDate(dayjs(date).format('YYYY-MM-DD'));
              }}
            />
          </div>
          <div className={b('button')}>
            <Button onClick={onClick}>{locale.showResults}</Button>
          </div>
        </div>
      </div>

      <div className={b('items')}>{list}</div>
    </div>
  );
};

export default TransactionHistory;
