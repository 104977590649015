import dayjs from 'dayjs';

export class CashierJackpotConverter {
  cashierJackpotConverter = data => {
    const result = {
      startAmount: data.StartAmount,
      lastWinners: data.LastWinners.map(item => ({
        ...item,
        winners: {
          ...item.winners,
          win_date: dayjs(item.win_date).format('YYYY-MM-DD:ss'),
        },
      })),
    };
    return result;
  };
}
