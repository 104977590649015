import React, { useMemo, useState } from 'react';
import block from 'bem-cn';
import SVGInline from 'react-svg-inline';
import { Link, useLocation } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';

import SVG from 'components/SVG';

import arrowSvg from './img/arrow.svg';

import './MenuItem.scss';

const b = block('menu-item');

const MenuItem = ({ locale, item, role = 1 }) => {
  const [isOpen, changeOpen] = useState(true);
  const location = useLocation();

  const { actual } = useSelector(state => state.notifyPaymentPending, shallowEqual);

  const itemList = useMemo(
    () =>
      item.items.map(temp => {
        if (temp.roles && !temp.roles.includes(+role)) return null;
        const isActive = location.pathname.startsWith(temp.route) || location.pathname.startsWith(temp.additionalRoute);

        const topUpIndicator = temp.withIndicatorTopUp && actual.filter(t => t.paymentType === 0).length;
        const withdrawalIndicator = temp.withIndicatorWithdrawal && actual.filter(t => t.paymentType === 1).length;

        return (
          <Link className={b('element', { active: isActive })} key={temp.textId} to={temp.route}>
            <span className={b('element-name')}>{locale[temp.textId]?.[role]}</span>
            {topUpIndicator ? (
              <div className={b('element-indicator')}>
                <div className={b('element-indicator-background')} />
                <div className={b('element-indicator-count')}>{topUpIndicator}</div>
              </div>
            ) : null}
            {withdrawalIndicator ? (
              <div className={b('element-indicator')}>
                <div className={b('element-indicator-background')} />
                <div className={b('element-indicator-count')}>{withdrawalIndicator}</div>
              </div>
            ) : null}
            <SVG className={b('element-arrow', { active: isActive })} svgProps={{ svg: arrowSvg }} />
          </Link>
        );
      }),
    [item.items, locale, role, location, actual],
  );

  return (
    <>
      <div className={b()}>
        <span className={b('name')}>{locale[item.textId]}</span>
      </div>
      {isOpen && <div className={b('sub-list')}>{itemList}</div>}
    </>
  );
};

export default MenuItem;
