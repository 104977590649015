import React from 'react';
import block from 'bem-cn';
import { useSelector } from 'react-redux';

import './WithdrawalHistoryItem.scss';
import Button from '../../../../../../components/Button';

const b = block('withdrawal-history-item');

const WithdrawalHistoryItem = ({ item }) => {
  const locale = useSelector(state => state.locale.locale);

  return (
    // <section className={b()}>
    //   <div className={b('top')}>
    //     <div className={b('item')}>
    //       <div className={b('item-text')}>{locale.username}</div>
    //       <div className={b('item-value')}>{item.userLogin}</div>
    //     </div>
    //
    //     <div className={b('item', { align: 'center' })}>
    //       <div className={b('item-text')}>ID</div>
    //       <div className={b('item-value')}>{item.id}</div>
    //     </div>
    //
    //     <div className={b('item', { align: 'end' })}>
    //       <div className={b('item-text')}>{locale.newRequest}</div>
    //       <div className={b('item-value')}>{item.amount}</div>
    //     </div>
    //   </div>
    //   <div className={b('bottom')}>
    //     <div className={b('item')}>
    //       <div className={b('item-text')}>{locale.userId}</div>
    //       <div className={b('item-value')}>{item.playerId}</div>
    //     </div>
    //     <div className={b('item', { align: 'center' })}>
    //       <div className={b('item-text')}>{locale.date}</div>
    //       <div className={b('item-value')}>{item.date}</div>
    //     </div>
    //     <div className={b('item', { align: 'end' })}>
    //       <div className={b('item-text')}>{locale.status}</div>
    //       <div className={b('item-value', { status: String(item.status) })}>{item.statusName}</div>
    //     </div>
    //   </div>
    // </section>
    <section className={b()}>
      <div className={b('item')}>
        <div className={b('item-text')}>ID</div>
        <div className={b('item-value')}>{item.id}</div>
      </div>

      <div className={b('item')}>
        <div className={b('item-text')}>{locale.date}</div>
        <div className={b('item-value')}>{item.date}</div>
      </div>

      <div className={b('item')}>
        <div className={b('item-text')}>{locale.userId}</div>
        <div className={b('item-value')}>{item.playerId}</div>
      </div>

      <div className={b('item')}>
        <div className={b('item-text')}>{locale.username}</div>
        <div className={b('item-value')}>{item.userLogin}</div>
      </div>

      <div className={b('item')}>
        <div className={b('item-text')}>{locale.newRequest}</div>
        <div className={b('item-value', { red: true })}>
          {locale.amount}: <span>{item.amount}</span>
        </div>
      </div>

      <div className={b('item')}>
        <div className={b('item-text')}>{locale.status}</div>
        <div className={b('item-value', { status: String(item.status) })}>{item.statusName}</div>
      </div>
    </section>
  );
};

export default WithdrawalHistoryItem;
