import dayjs from 'dayjs';
import { addNotify } from 'features/notify';

const actionTypes = {
  ACTION_PROCESSING: 'user/ACTION_PROCESSING',
  GET_TRANSACTIONS_SUCCESS: 'user/GET_TRANSACTIONS_SUCCESS',
  GET_ACTIVE_REQUESTS: 'user/GET_ACTIVE_REQUESTS',
  GET_WITHDRAWAL_HISTORY: 'user/GET_WITHDRAWAL_HISTORY',
};

function getTransactions(period) {
  return async (dispatch, getState, extra) => {
    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });
    const { api } = extra;
    const newPeriod = {
      startDate: dayjs(period.startDate).format('YYYY-MM-DD'),
      endDate: dayjs(period.endDate).add(1, 'day').format('YYYY-MM-DD'),
    };
    const response = await api.transaction.getTransactions(newPeriod);
    if (response.success) {
      dispatch({ type: actionTypes.GET_TRANSACTIONS_SUCCESS, payload: response.data });
    } else {
      dispatch(addNotify('Error', 'error'));
      dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
    }
  };
}

function getActiveRequests() {
  return async (dispatch, getState, extra) => {
    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });

    const { api } = extra;
    const response = await api.transaction.getActiveRequests({ status: 0 });

    const errorMessage = response.errorMessage || getState().locale.locale.defaultMessage;

    if (response.success) {
      dispatch({ type: actionTypes.GET_ACTIVE_REQUESTS, payload: response.data });
    } else {
      dispatch(addNotify(errorMessage, 'error'));
    }

    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
  };
}

function setRequestStatus({ callback, ...data }) {
  return async (dispatch, getState, extra) => {
    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });

    const { api } = extra;
    const response = await api.transaction.setRequestStatus(data);
    const locale = getState().locale.locale;

    const errorMessage = response.errorMessage || locale.defaultMessage;

    if (response.success) {
      dispatch(addNotify(locale.defaultSuccess, 'success'));
    } else {
      dispatch(addNotify(errorMessage, 'error'));
    }

    if (callback) callback(response.success);

    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
  };
}

function getWithdrawalHistory(data) {
  return async (dispatch, getState, extra) => {
    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });

    const { api } = extra;

    const response = await api.transaction.getActiveRequests(data);

    const errorMessage = response.errorMessage || getState().locale.locale.defaultMessage;

    if (response.success) {
      dispatch({
        type: actionTypes.GET_WITHDRAWAL_HISTORY,
        payload: response.data,
      });
    } else {
      dispatch(addNotify(errorMessage, 'error'));
    }

    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
  };
}

export { actionTypes, getTransactions, getActiveRequests, setRequestStatus, getWithdrawalHistory };
