import React from 'react';
import { useSelector } from 'react-redux';
import SVGInline from 'react-svg-inline';
import block from 'bem-cn';

import Button from 'components/Button';

import crossSVG from './img/cross.svg';
import './ActiveRequestModal.scss';

const b = block('active-request-modal');

const ActiveRequestModal = ({ item, note, onNoteChange, onClose, onSend }) => {
  const locale = useSelector(state => state.locale.locale);

  if (item === null) return null;

  return (
    <section className={b()}>
      <div className={b('content')}>
        <div className={b('header')}>
          {locale.requestRejection}
          {/*<SVGInline className={b('cross').toString()} onClick={onClose} svg={crossSVG} />*/}
        </div>
        <div className={b('reject')}>
          {locale.youRejected}
          <span className={b('amount')}>{item.amount}</span>
        </div>
        <div className={b('comment')}>{locale.comment}</div>
        <textarea
          className={b('note')}
          rows={8}
          value={note}
          placeholder={locale.writeExplanation}
          onChange={e => onNoteChange(e.currentTarget.value)}
        />
        <div className={b('button')}>
          <Button onClick={onSend} disabled={!Boolean(note)}>
            {locale.send}
          </Button>
        </div>
      </div>
    </section>
  );
};

export default ActiveRequestModal;
