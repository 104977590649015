import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

export class PaymentAdminConverter {
  convertBanks(data) {
    return data?.payments ?? [];
  }

  convertItem = t => ({
    id: t.id,
    date: dayjs(t.create_date || t.date, 'YYYY-MM-DD:ss').format('YYYY-MM-DD:ss'),
    amount: t.amount,
    status: t.status,
    comment: t.comment,
    paymentSystem: t.payment_system,
    paymentRequisite: t.payment_requisite,
    transactionId: t.id_of_the_transaction,
    imageUrl: `https://bossi.bet${t.img_url}`,
    bank: t.bank,
    requisite: t.requisite,
    playerId: t.player_id ?? '',
  });

  convertActiveRequests = data => {
    const convertRequestsHistoryformattedRequests = data?.data?.map(t => this.convertItem(t));

    return {
      activeRequests: formattedRequests,
      pages: data?.meta?.pages ?? 1,
    };
  };

  convertRequestsHistory = data => {
    const withdrawals = data?.data?.withdrawals?.map(t => this.convertItem(t));
    const deposits = data?.data?.deposits?.map(t => this.convertItem(t));

    return {
      withdrawals,
      deposits,
      pages: data?.meta?.pages ?? 1,
    };
  };

  convertWithdrawalRequestsHistory = data => {
    const withdrawals = data?.data?.map(t => this.convertItem(t));

    return {
      withdrawals,
      pages: data?.meta?.pages ?? 1,
    };
  };

  convertBankWithdrawals = data => {
    return data.sort((a, b) => dayjs(b.create_date).unix() - dayjs(a.create_date).unix());
  };
}
